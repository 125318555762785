<template>
    <div>
        <smallbutton @click="UpdateVendor()" :toplabel="updateText" :class="buttonclass"/>
        <!-- ACCT NOTE MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <form ref="myform">
                    <label class="titlelabel">{{updateText}}</label>
                    
                    <formentry  inputtype="text"  label="Name" :isrequired="isRequired('Name')"  v-model="Vendor.name" v-if="isVisible('Name')"/>
                    <div class="entry"  v-if="isVisible('IsActive')">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">Is Active</label>
                        </div>
                        <div class="entryTextDiv">
                            <input class="entryCheckbox" type="checkbox" v-model="Vendor.isActive" >
                        </div>
                    </div>
                    
                    <br>
                    <formentry  inputtype="text"  label="Contact Person"  v-model="Vendor.contactPerson"  v-if="isVisible('ContactPerson')" :isrequired="isRequired('ContactPerson')"/>
                    <formentry  inputtype="email"  label="Email Address"  v-model="Vendor.contact.EmailAddress"  v-if="isVisible('Email')" :isrequired="isRequired('Email')"/>
                    <formentry  inputtype="tel"  label="Phone Number" @blur="leavePhone"  v-model="Vendor.contact.PhoneNumber1"  v-if="isVisible('Phone')" :isrequired="isRequired('Phone')"/>
                    

                    <formentry label="Address 1"  v-model="Vendor.contact.AddressLine1"  v-if="isVisible('Address')" :isrequired="isRequired('Address')"/>
                    <formentry label="Address 2"  v-model="Vendor.contact.AddressLine2" v-if="isVisible('Address')"/>
                    <formentry label="City"  v-model="Vendor.contact.City" v-if="isVisible('Address')" :isrequired="isRequired('Address')"/>
                    <div class="entry" v-if="isVisible('Address')">
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">State</label>
                        </div>
                        <div class="entryTextDiv">
                        <select name="" id="" class='entrySelectVendor' v-model="Vendor.contact.StateID" :required="isRequired('Address')">
                            <option v-for="state in states" :key="state.id" :value="state.id" >
                                {{state.name}}
                            </option>
                        </select>
                        </div>
                    </div>
                    <formentry label="Zip"  v-model="Vendor.contact.ZipCode" v-if="isVisible('Address')" :isrequired="isRequired('Address')"/>
                    <br>
                    <formentry  inputtype="text"  label="Order Day of Week"  v-model="Vendor.orderDayOfWeek"  v-if="isVisible('orderDayOfWeek')" :isrequired="isRequired('orderDayOfWeek')"/>
                    <formentry  inputtype="text"  label="Min Order Amount ($)"  v-model="Vendor.minimumOrderTotalAmount"  v-if="isVisible('minimuOrderTotalAmount')" :isrequired="isRequired('minimuOrderTotalAmount')"/>
                    <formentry  inputtype="text"  label="Min Order Qty"  v-model="Vendor.minimumOrderTotalQuantity"  v-if="isVisible('minimumOrderTotalQuantity')" :isrequired="isRequired('minimumOrderTotalQuantity')"/>

    
                    <smallbutton  @click="saveNewVendor()" toplabel="Save" v-if="!needConfirm"/>
                    <smallbutton  @click="saveNewVendor(true)" toplabel="Confirm Save" v-if="needConfirm"/>
                    <smallbutton  @click="cancelupdate()" toplabel="Cancel"/>
                    <div>
                        <label class="responsemessage">{{message}}</label>
                   </div>

                   <!-- <confirm ref="overrideDuplicate" :showButton="paramFalse" :body="confirmVendorMessage" title="Add Vendor" @yes="saveNewVendor(true)"   /> -->
              </form>

            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import datamethods from '../../services/datamethods.js'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import actionmodal from '../actions/actionmodal.vue'
    import formentry from '../controls/entry.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import pencilbutton from '../controls/pencilbutton.vue'

    export default {
        name:'updateVendor',
        components:{
            smallbutton,
            actionmodal,
            formentry,
            linkbutton,
            pencilbutton,
            
        },
        props:{
            inVendor:{
                type:Object,
                required:true
            },
       
            isAdd:{
                type:Boolean,
                default:false
            },
            buttonclass:{
                type:String,
                default:'updatebutton'
            },
 
    
        },
        mixins:[datamethods,format],
        data(){
            return{
                message:'',
                Vendor:{
                    id:0,
                    name:null,
                    isActive:true,
                    contact:{
                        AddressLine1:null,
                        AddressLine2:null,
                        City:null,
                        StateID:-1,
                        StateName:null,
                        ZipCode:null,
                        EmailAddress:null,
                        PhoneNumber1:null
                    },
                    contactPerson:null,
                    accountNumber:null,
                    orderDayOfWeek:null,
                    minimumOrderTotalAmount:0,
                    minimumOrderTotalQuantity:0
                },
                confirmVendorMessage:null
            }
            
        },
        computed:{
            ...mapFields([
               'locationList',
               'states'
            ]),
           
            updateText(){
                if(this.isAdd){
                    return "Add Vendor" 
                }else{
                    return "Update Vendor" 
                }
                
            },
       
        },
        methods:{
            toCurrency(value){
                return "$" + Number(value).toFixed(2);
            },
            isRequired(field){
                
                switch (field) {
                    case "Name":
                        return true
                        break;
                    

                    default:
                        return false
                        break;
                }
            },
            isVisible(field){
                
               // console.log('isvis ' + field)
                switch (field) {
         
                    default:
                        return true
                        break;
                }
            },
            toCurrency(value){
                return  Number(value).toFixed(2);
            },
           UpdateVendor(){
            this.needConfirm=false
            this.message=null
            if(this.isAdd){
                this.Vendor = {
                    id:0,
                    name:null,
                    isActive:true,
                    contact:{
                        AddressLine1:null,
                        AddressLine2:null,
                        City:null,
                        StateID:-1,
                        StateName:null,
                        ZipCode:null,
                        EmailAddress:null,
                        PhoneNumber1:null
                    },
                    contactPerson:null,
                    accountNumber:null,
                    orderDayOfWeek:null,
                    minimumOrderTotalAmount:0,
                    minimumOrderTotalQuantity:0
                    
              
                }
                
            }else{
                this.Vendor = this.inVendor
                this.Vendor.minimumOrderTotalAmount = this.toCurrency(this.Vendor.minimumOrderTotalAmount)
      
                
            }
            

            this.$refs.mymodal.openModal()
           },
            
            checkClass(selected){
                if(selected){
                    return "entryCheckLabelbold"
                }else{
                    return "entryCheckLabel"
                }
            },
            cancelupdate(){
                this.$refs.mymodal.closeModal()
                this.$emit('refresh')
                
            },
            async saveNewVendor(overridedup=false){
                this.message=null
                if(this.$refs.myform.checkValidity()==false){
                    this.message = "Please fill in all required fields"
                    return}
                
                
                this.Vendor.locationId=-1
                
                let req = {
                    Vendor:this.Vendor,
                    allowEmailPhoneDuplicate:overridedup
              
                }
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'updatevendor')
                if(response.Successful){
                    this.needConfirm=false
                    this.$refs.mymodal.closeModal()
                    //console.log(response)
    
                    if(this.isAdd){
                        this.$emit('refreshnew',response.ResponseInteger)
                    }else{
                        this.$emit('refresh')
                    }
                    
                }else{
                    if(response.Message.includes('Do you want to add anyway?')){
                        this.message = response.message
                        this.needConfirm=true
                    }
                    this.message = response.Message
                }
                this.pleaseWait=false;
            },
            
             
           
            
           
        }
    }
    </script>
    
    <style>
    .entrySelectVendor{
    margin:5px 5px 5px 5px;
    width:100%;
    height:25px;
    font-size:inherit;
}
    .entryCheckLabel{
        margin:0px 0px 0px 5px;
        font-size:medium;
        text-align: left;  
        max-width: 420px;
    }
    .entryCheckLabelbold{
        margin:0px 0px 0px 5px;
        font-size:medium;
        text-align: left;  
        max-width: 420px;
        font-weight:bold;
    }
    .updatebutton{
        height:30px;
        width:150px;
        border-radius: 5px;
        padding:3px;
        margin:0px 0px 10px 0px;
    }
    .locationcheck{
    height:15px;
    margin-top:15px;
    margin-right:5px;
}
.locationdiv{
    text-align: left;
    display:grid;
    grid-template-columns: 1fr 15fr;
}
.locationCheckbox{
    height:15px;
    margin-top:15px;
}
    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    