//import Vue from 'vue'
//import Vuex from 'vuex'

import { transformRef } from '@vue/compiler-sfc';
import { createStore } from 'vuex'
import {getField, updateField} from 'vuex-map-fields'

//Vue.use(Vuex)
//export const store = new Vuex.Store({
export const store = createStore({
    state:{
        //general
        username:'',
        oldpassword:'',
        password:'',
        loggedInUser:{
            name:'User',
            user_personId:0,
            LocationId:0
        },
        loggedIn:false,
        securityAppName:'POSAdmin',
        securityAppItems:[],
        showHeader:true,
        robot:true,
    
 
       
        
        pleaseWait:false,
        responseMessage:'',

        //group
        selectedGroupID:-1,
        selectedGroupName:null,
        selectedGroup:null,

        selectedGroupLocationId:-1,
        selectedGroupLocationName:null,
        selectedProductID:-1,
        selectedProductName:null,
        selectedProductPrice:0,
        selectedGroupProductID:-1,
        selectedGroupProductName:null,
        groupList:[],
        productList:[],
        groupProductList:[],
        
        //product
        locationList:[],
        locationId:-1,
        productString:null,
        showInactive:false,
        lookupresults:[],
        selectedResultID:0,
        currentProduct:{id:0,name:null},
        barcodesURL:null,
        pricelabelURL:null,

        //inventory
        inventoryFilter:{
            locationId:-1,
            typeId:-1,
            lineId:-1,
            vendorId:-1,
            filterString:null
        },

        //open close
        ocLocationList:[],
        terminalList:[],
        location:{
            id:-1
        },
        terminalId:-1,

        openClose:{},
        cashTotal:0.00,
        cashOverShort:0.00,
        currentNote:null,
        OpenCloseMessageString:null,
        OpenCloseMessageTitle:null,
        HeaderOpenCloseMessageString:null,

        //inventory
        productTypeList:[],
        productLineList:[],
        vendorList:[],
        reportImageBasePath:null,
        inventoryBelowTargetList:[],
        isInventoryBelowTarget:false,
        inventoryList:[],
        inventoryTotals:{
            onHandTotal:0
        },
        
        //library
        libraryFilter:{
            locationId:-1,
            groupId:-1,
            studentId:-1,
            studentCode:null,
            libraryItemStatusId:-1
        },

        //trans
        transLocationList:[],
        transLocation:{
            id:-1
        },

        //vendor
        vendorResults:[],
        states:[],
        selectedResultID_Vendor:0
    },
    mutations:{
        updateField,
        resetbeforeapi(state){
            state.pleaseWait=true;
            state.responseMessage=''
        },
    },
    getters:{
        getField,
    },
    actions:{
        beforeAPI(context){
            context.commit('resetbeforeapi'); 
        },
        
    }
})