<template>
    <div class="lookupdetail">
        <div class="lookup">
            <div class="lookupHeader">
                <label class="titlelabel bottom10">Vendors</label>
                       
            </div>
            <div class="horizFlex ">
                <input class="entryCheckbox" type="checkbox" v-model="showInactive" @change="lookup">
                <label for="" class="mediumtext">Show Inactive</label>
            </div>
            <div class="leftjustified">
                <updateVendor @refresh="Refresh" :isAdd="trueParam" buttonclass="innerbutton" @refreshnew="Refresh"/>
            </div>
            
            <div class="resultsdiv" v-if="vendorResults.length>0">
                <div v-for="result in vendorResults" :key="result.id" :value="result.id" >
                    <lookupresult :topLeft="buildResultName(result)"   @click="select(result)"
                        :selected="result.id==selectedResultID_Vendor" :inactive="!result.isActive">
                    
                    </lookupresult>  
                </div>
            </div>
        </div>
  
        <div v-if="selectedResultID_Vendor>0" class="pad10">
            <div class="bordered">
                <div class="horizFlexStretch">
                    <div class="horizFlex">
                        <label class="titlelabel bold right10">{{currentVendor.name}} </label>
                        <updateVendor :inVendor="currentVendor"  @refresh="Refresh"  />
                    </div>
                   <div></div>
                </div>
                <div class="splitDiv ">
                    <!-- left col -->
                    <div class="leftjustified vertFlex padright">
                        
                        
                        <div class="dataitem_vendor" v-if="isVisible('Name')">
                            <p >Name:</p>
                            <p >{{currentVendor.name}}</p>
                        </div>
                        
                        <div class="dataitem_vendor"  v-if="isVisible('Email')">
                            <p >Email:</p>
                            <p >{{currentVendor.contact.EmailAddress}} </p>
                        </div>
                        <div class="dataitem_vendor"  v-if="isVisible('Phone')">
                            <p >Phone:</p>
                            <p >{{currentVendor.contact.PhoneNumber1}}</p> 
                        </div>
                        <div class="dataitem_vendor"  v-if="isVisible('Address')">
                            <p >Address:</p>
                            <p >{{currentVendor.contact.AddressLine1}}</p>
                        </div>
                        <div class="dataitem_vendor"  v-if="isVisible('Address')">
                            <p >Address 2:</p>
                            <p >{{currentVendor.contact.AddressLine2}}</p>
                        </div>
                        <div class="dataitem_vendor"  v-if="isVisible('Address')">
                            <p >City/ST/Zip:</p>
                            <p >{{currentVendor.contact.City}}, {{currentVendor.contact.StateName}} {{currentVendor.contact.ZipCode}}</p>
                        </div>
                        <hr>
                        <div class="dataitem_vendor"  v-if="isVisible('Address')">
                            <p >Order Day of Week:</p>
                            <p >{{currentVendor.orderDayOfWeek}}</p>
                        </div>
                        <div class="dataitem_vendor"  v-if="isVisible('Address')">
                            <p >Min Order Amount:</p>
                            <p >{{toCurrency(currentVendor.minimumOrderTotalAmount)}}</p>
                        </div>
                        <div class="dataitem_vendor"  v-if="isVisible('Address')">
                            <p >Min Order Qty:</p>
                            <p >{{currentVendor.minimumOrderTotalQuantity}}</p>
                        </div>
                    </div>

                    <!-- right col -->
                    <div class="leftjustified vertFlex ">
                        
                        

                        

                            
                         



                    </div>
                </div>
                
            </div>

        </div>
        
    </div>
  </template>
  
  <script>
  import {mapFields} from 'vuex-map-fields'
  import datamethods from '../../services/datamethods.js'
  import format from '../../format.js'
  import smallbutton from '../controls/button.vue'
  import formentrystacked from '../controls/entry-stacked.vue'
  import entrySelect from '../controls/entry-select'
  import lookupresult from '../controls/lookupresult.vue'
  import linkbutton from '../controls/linkbutton'
  import updateVendor from './updatevendor.vue'

  export default {
      name:'Vendordmin',
      mixins:[datamethods, format],
      components:{
          smallbutton,
          formentrystacked,
          entrySelect,
          lookupresult,
          linkbutton,
          updateVendor,

      },
      computed:{
          ...mapFields([
    
              'pleaseWait',
              'pleaseWaitText',
              'lastRefreshDateTime',
              'imagepath',
            'showInactive',
            'vendorResults',
            'selectedResultID_Vendor',
            'currentVendor',
            'loggedInUser',
            'states'
          ]),
          itemviewclass(){

              return 'itemview'
          },


      },
      mounted(){
        this.getstates()
        this.lookup()
     
      },
      data(){
          return{
        
            responseMessage:'',
            trueParam:true,
            falseParam:false,
            listenersConnected:true,

          }
      },
      methods:{
        async getstates(){
            this.message = ''
            var response = await this.callAPI(null,'states')    
            if(response.Successful){
                this.states=response.stateList
            }else{
                this.message = response.Message
            }

        },
        toCurrency(value){
            return "$" + Number(value).toFixed(2);
        },
        isVisible(field){
            switch (field) {
      
                    default:
                        return true
                        break;
                }
        },
          buildResultName(result){
              var name = result.name
  
              return name
          },
          
         
          select(result){
              this.selectedResultID_Vendor=result.id
              this.getItem(result.id)
          },
          Refresh(id){
              console.log('refresh ' + id)
              if(id==undefined){id=this.currentVendor.id}
              this.lookup();
              this.selectedResultID_Vendor=id
              this.getItem(id)
          },
          RefreshCurrent(){
              this.Refresh(this.currentVendor.id)
          },
          
          async lookup(){
            this.responseMessage=null

            this.vendorResults=[]
            let req = {

                includeInactive:this.showInactive
            }
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'vendorforadmin')
                //handle response
            
                if(response.Successful){
                    this.vendorResults=response.vendorList
                    if(this.vendorResults.length==0){
                        this.responseMessage="No Vendors Found"
                    }
                }else{
                this.responseMessage = response.Message
                }
                this.pleaseWait=false;   
             
          },
          
          async getItem(id){
        
     
              let req = {
                  ID:id,

              }
              this.$store.dispatch('beforeAPI');
              var response = await this.callAPI(req,'getvendor')
              //handle response
              if(response.Successful){
                   this.currentVendor =response.vendor;
                  
                
              }else{
                  this.responseMessage = response.Message
              }
              this.pleaseWait=false;  
  
          },
          
       
    },
    
  }
  </script>
  
  <style>
  .reportLink{
    margin-top:40px;
    margin-left:10px;
  }
  .innerbutton{
    height:40px;
    border-radius: 5px;
}
  .splitDiv{
    border-radius:5px;
    margin:20px 0px 0px 0px;
    padding:0px;
    display:grid;
    grid-template-columns:1fr 1fr;
    text-align: left;
}
  .lookupdetail{
       margin:0px;
      display:grid;
      grid-template-columns:1fr 4fr;
      grid-template-rows:auto;
      grid-template-areas: 
      "lookup detail"
  }
  
  .tokenname{
    margin:0px 10px 0px 0px ;
  }
  .note{
    word-wrap:break-word;
  }
  .resultsdiv{
  
      height:350px;
      overflow-x:hidden;
      overflow-y:scroll;
  }
  .transdiv{
      height:250px;
      overflow-x:hidden;
      overflow-y:scroll;
  }
  .itemview{
      padding-left:20px;
      
  }

  .refresh{
      display:flex;
      flex-direction: column;
      text-align: right;
      margin-right:20px;
  }
  .margintop{
      margin-top:5px;
  }
  .lookupHeader{
    display:flex;
    padding:0px;
    flex-direction: row;
    margin:0px 0px 0px 0px ;
    justify-content:space-between;
    width:100%;
  }
  .clearlink{
      margin-top:5px;
  }

  .entry{
    width: 100%;
    display:flex;

    justify-content: space-between;
}
.entryLabel{
    margin:5px;
    font-size:medium;
    text-align: left;   
}
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.entryCheckbox{
    margin-left:5px;
}
.entryText{
    margin:5px;
    width:95%;
    font-size:inherit;
    font:inherit;
}
.entrySelect{
    margin:0px 0px 10px 0px;
    width:100%;
    height:25px;
    font-size:inherit;
}
.entryTextDiv{
    display:flex;
    flex-direction: row;
    min-width: 250px;
}

.dataitem_vendor{
    display:grid;
    grid-template-columns: 160px auto;
    margin:0px;
    padding:0px;
}
.dataitemwithcheck{
    display:grid;
    grid-template-columns: 100px auto 25px 150px;
    margin:0px;
    padding:0px;
}
.dataitem_vendor p{
    margin:3px;
}
.dataitemwithcheck p{
    margin:3px;
}

hr{
    border:solid 1px lightgray;
    width:95%;  
    margin-top:20px;
    margin-bottom:20px;
}
.locationcheck{
    height:15px;
    margin-top:15px;
    margin-right:5px;
}
.locationdiv{
    display:grid;
    grid-template-columns: 1fr 15fr;
}

  </style>