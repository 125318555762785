<template>
    <div class="pmtdiv">
        <smallbutton :toplabel="topLabel" @click="start()" v-if="showButton" :buttonclass="buttonClass"/>
        <linkbutton :toplabel="topLabel" @click="start()" v-if="!showButton"/>
        <!--  MODAL -->
          <actionmodal ref="mymodal"> 
            <template v-slot:body>
                <label class="titlelabel">{{titletext}}</label>
                <div class="entry"  >
                    <div class="entryTextDiv">
                        <label class="entryLabel bold" for="">Select Server</label>
                    </div>
                    <div class="entryTextDiv">
                    <select name="" id="" class='entrySelect' required v-model="ticket.employeeId"  >
                        <option v-for="s in personList" :key="s.personId" :value="s.personId" >
                            {{s.name}}
                        </option>
                    </select>
                    </div>
                </div>
    

                    <div>
                        <label class="responsemessage">{{ responseMessage }}</label>
                    </div>
                    <div>
                        <smallbutton toplabel="Save" @click="savetip" ></smallbutton>
                        <smallbutton toplabel="Cancel" @click="cancel"></smallbutton>
                    </div>
            </template>
          </actionmodal>
        </div>
    </template>
    
    <script>
    import {mapFields} from 'vuex-map-fields'
    import format from '../../format.js'
    import smallbutton from '../controls/button.vue'
    import linkbutton from '../controls/linkbutton.vue'
    import datamethods from '../../services/datamethods.js'
    import actionmodal from '../controls/actionmodal.vue'

    export default {
        name:'updatetip',
        components:{
            smallbutton,
            linkbutton,
            actionmodal,
 
        },
        props:{
           
            showButton:{
                type:Boolean,
                default:false
            },
            topLabel:{
                type:String
            },
            ticketId:{
                type:Number
            },
            buttonClass:{
                type:String,
                default:'button buttonprimary bottom10'
            }
            
        },
        mixins:[format, datamethods],
        data(){
            return{
                paramTrue:true,
                paramFalse:false,
                trueParam:true, 
                ticket:null,
                ticketTip:{},
                isDirty:false,
                personList:[]

            }
        },
        computed:{
            ...mapFields([
                'responseMessage',
                'pleaseWait',
                'location',
                'terminalId',
             
     
            ]),
            
           
            titletext(){
                return "Update Server"
            },
            
        },
        methods:{
            cancel(){
                this.responseMessage=null
                this.$refs.mymodal.closeModal()
            },
            
 
            start(){
                this.getPersonList()
                this.refreshticket()
                this.responseMessage=null
                this.isDirty=false
                this.$refs.mymodal.openModal()
                
            },
            async getPersonList(){
         
                var req={
                    lookup:{
                        restrictToStudents:false,
                        restrictToEmployees:true
                    }
                    
                }
                

                //call api
                this.$store.dispatch('beforeAPI');
                this.responseMessage=null
                var response = await this.callAPI(req,'tipemployeelist')
                //handle response
            
                if(response.Successful){
                    
                    this.personList= response.ResultList
               
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;

            },
            async savetip(){
                if(this.inSave){return}

               if(this.ticketTip==null){
                this.ticketTip = {
                    ticketId:this.ticket.id,
                    employeeId:this.ticket.employeeId
                }
               }else{
                this.ticketTip.employeeId=this.ticket.employeeId
               }
                

                let req = {
                    ticketTip:this.ticketTip,

                }  
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'updatetickettip')
                //handle response
                this.inSave=false
                if(response.Successful){
                    
                    this.$emit('refresh')
                    this.$refs.mymodal.closeModal()
                }else{
                    this.responseMessage = response.Message
                }
                this.pleaseWait=false;
                this.inSave=false
            },

            async refreshticket(){
                let req = {
                    ticket:{
                        id:this.ticketId
                    }
                }  
                    //call api
                    this.$store.dispatch('beforeAPI');
                    var response = await this.callAPI(req,'ticket')
                    //handle response
                
                    if(response.Successful){
                        this.ticket = response.ticket
                        /* if(this.ticket.tipItems.length==0){
                            this.responseMessage="There are no tips on this ticket"
                            return
                        } */
                       if(this.ticket.tipItems.length>0){
                        this.ticketTip=response.ticket.tipItems[0]
                       }else{
                        this.ticketTip=null
                       }
                        
                        

                    
                    
                    }else{
                    this.responseMessage = response.Message
                    }
                    this.pleaseWait=false;
                },
            
        }
    }
    
    </script>
    
    <style>
    .ticketlineitem{
        display:grid;
        grid-template-columns: 1fr 3fr 1fr;
    }
    .paymentitem{
        display:grid;
        grid-template-columns: 1fr 3fr 1fr;
    }
    .ticketfield{
        min-width:100px;
    }
    .ticketrecord{
        display:grid;
        grid-template-columns: 1fr 1fr 4fr 1fr 1fr 2fr 2fr;
    }
    .leftjustify{
        text-align: left;
    }
    .rightjustify{
        text-align: right;
    }
    .payfull {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 80px;
  grid-template-areas:
    "payavailable payadded"
    "open next";
  padding: 20px;
}
    .payitemavailable {
  grid-area: payavailable;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height:100px;
}
.payitemadded {
  grid-area: payadded;
  display: flex;
  flex-direction: column;
  border-left: 1px solid gray;
  padding-left:20px;
  margin-left:10px;
  text-align:right;
  align-items: right;
}
.btn {
  background-color: var(--button-color);
  color: var(--light-text-color);
  margin: var(--margin-default);
  border: none;
  font-size: inherit;
  border-radius: var(--border-radius-default);
  padding: var(--padding-bigger);
  
}
.paymentbutton {
  background-color: var(--color-payment);
  color: var(--light-text-color);
  min-width:120px;
}
.cashpaymentbutton{
  background-color: var(--color-cash);
  color: var(--light-text-color);
  min-width:120px;
}
.subtitle2 {
  font-size: 20px;
  
}
.text-right {
  text-align: right;
  margin:5px;
  
}
.text{
    margin:5px;
}
.totaltext{
  font-size:larger;
  
}
.paycolor{
  color:var(--color-payment);
  font-weight:bold;
}

    </style>
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    