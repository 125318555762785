<template>
   <div class="inventory">
        <div class="filter horizFlex wrap "  >
            <p class="filterlabel">Lookup</p>
            
            <select name="" id="" class='filterselect' v-model="inventoryFilter.locationId" required @change="selectLocation">
                <option v-for="loc in locationList" :key="loc.id" :value="loc.id" >
                    {{loc.name}}
                </option>
            </select>
            <select name="" id="" class='filterselect' v-model="inventoryFilter.typeId" required >
                <option v-for="loc in productTypeList" :key="loc.id" :value="loc.id" >
                    {{loc.name}}
                </option>
            </select>
            <select name="" id="" class='filterselect' v-model="inventoryFilter.lineId" required >
                <option v-for="loc in productLineList" :key="loc.id" :value="loc.id" >
                    {{loc.name}}
                </option>
            </select>
            <select name="" id="" class='filterselect' v-model="inventoryFilter.vendorId" required >
                <option v-for="loc in vendorList" :key="loc.id" :value="loc.id" >
                    {{loc.name}}
                </option>
            </select>
            <input class='filterinput2' v-model="inventoryFilter.filterString" placeholder="Product Name/SKU" />

            <div class="horizFlex ">
                <input class="entryCheckbox" type="checkbox" v-model="inventoryFilter.includeInactive" >
                <label for="" class="inactivelabel">Show Inactive</label>
            </div>

            <smallbutton toplabel="Go" @click="filterinventory" buttonclass="buttonprimary narrowbutton"/>
            <smallbutton toplabel="Reset" buttonclass="buttonprimary narrowbutton" @click="resetfilter"/>
            
            
            
        </div>

        <div class="margin10" v-if="pleaseWait">
                <label class="pleasewait">Please Wait...</label>
        </div>
     
        <div class="horizFlexStretch">
            <p class="bold">{{ totalsString }}</p>
            <div class="horizFlexStretch" >
                <confirm :showButton="paramTrue" :isAlert="paramTrue" :body="setonhandwarning" title="Set On Hand" @yes="setonhand()"  buttonclass=" buttonprimary invbutton"/>
                <onhand buttonclass="buttonprimary invbutton" ref="onhand" :setonhand="paramTrue"  @refresh="filterinventory"/>
                <onhand buttonclass="buttonprimary invbutton" ref="consume" :consume="paramTrue" :showButton="paramTrue" @refresh="filterinventory"/>
                <onhand buttonclass="buttonprimary invbutton" ref="receive" :receive="paramTrue" :showButton="paramTrue" @refresh="filterinventory"/>
                <smallbutton toplabel="Print Inventory" buttonclass="buttonprimary invbutton" @click="printInventory"/>
                <actionset buttonclass="buttonprimary invbutton"/>
                <confirm :showButton="paramTrue" :isAlert="paramTrue" :body="resetallonhandwarning" title="Reset ALL On Hand" @yes="resetonhand()"  buttonclass=" buttonprimary invbutton" :disabled="!canAccess('ResetAllOnHandTo0')"/>
                <belowtarget :showButton="paramTrue" buttonclass=" buttonprimary invbutton"/>
                <!-- <smallbutton toplabel="Receive Stock" buttonclass="buttonprimary invbutton" @click=""/>
                <smallbutton toplabel="Consume Stock" buttonclass="buttonprimary invbutton" @click=""/> -->
            </div>
            
        </div>
        <div class="horizFlexStretch">
            <div></div>
            <div class="urlclass" v-show="false">
                <a ref="inventoryreportlink" :href="reportURL" target="_blank" >Click to view or print the Inventory Report </a>
            </div>
        </div>
        
        <div >
            <div class="invheader" >
                <linkbutton class="invrecordlabel2 bold" @click="setsort('loccation')" toplabel="Location"/>
                <linkbutton class="invrecordlabel2 bold" @click="setsort('code')" toplabel="Code"/>
                <linkbutton class="invrecordlabel2 bold" @click="setsort('name')" toplabel="Product Name"/>
                <linkbutton class="invrecordlabel2 bold" @click="setsort('line')" toplabel="Product Line"/>
                <linkbutton class="invrecordlabel2 bold" @click="setsort('status')" toplabel="Status"/>
                <linkbutton class="invrecordlabel2 bold rightjustified" @click="setsort('size')" toplabel="Size"/>
                <linkbutton class="invrecordlabel2 bold rightjustified" @click="setsort('cost')" toplabel="Cost"/>
                <linkbutton class="invrecordlabel2 bold rightjustified" @click="setsort('price')" toplabel="Price"/>
                <linkbutton class="invrecordlabel2 bold rightjustified" @click="setsort('target')" toplabel="Reord. Alert"/>
                <linkbutton class="invrecordlabel2 bold rightjustified" @click="setsort('preforder')" toplabel="Prefer. Ord."/>
                <linkbutton class="invrecordlabel2 bold rightjustified" @click="setsort('onhand')" toplabel="On Hand"/>

                
            </div>
            <div class="vertScrollinv2">
                <div v-for="g in sortedList" :key="g.ID" :value="g.ID" :class="recordClass(g)">
                    <p class="invrecordlabel2">{{g.locationName}}</p>
                    <p class="invrecordlabel2">{{g.code}}</p>
                    <p class="invrecordlabel2">{{g.name}}</p>
                    <p class="invrecordlabel2">{{g.lineName }}</p>
                    <p class="invrecordlabel2" v-if="g.isActive">Active</p>
                    <p class="invrecordlabel2" v-if="!g.isActive">Inactive</p>
                    <p class="invrecordlabel2  rightjustified">{{g.size}}</p>
                    <p class="invrecordlabel2 rightjustified">{{formatmoney(g.unitCost)}}</p>
                    <p class="invrecordlabel2 rightjustified">{{formatmoney(g.unitPrice)}}</p>
                    <p class="invrecordlabel2  rightjustified">{{g.targetQty}}</p>
                    <p class="invrecordlabel2  rightjustified">{{g.preferredOrderQty}}</p>
                    <p class="invrecordlabel2  rightjustified">{{g.onHandQty}}</p>


                   
                </div>
            </div>
            
        </div>

    </div>
  </template>
  
  <script>
  import {mapFields} from 'vuex-map-fields'
  import datamethods from '../../services/datamethods.js'
  import datamethodsreport from '../../services/datamethods-report.js'
  import format from '../../format.js'
  import smallbutton from '../controls/button.vue'
  import confirm from '../actions/confirm.vue'
import linkbutton from '../controls/linkbutton.vue'
import onhand from '../inventory/onhand.vue'
import actionset from '../inventory/actionset.vue'
import securityhelper from '../../services/securityhelper.js'
import belowtarget from './belowtarget.vue'
import Belowtarget from './belowtarget.vue'
  export default {
      name:'inventory',
      mixins:[datamethods,datamethodsreport,format,securityhelper],
      components:{
        smallbutton,
        linkbutton,
        onhand,
        confirm,
        actionset,
        belowtarget
      },
      computed:{
          ...mapFields([
    
              'pleaseWait',
              'pleaseWaitText',
              'inventoryFilter',
              'locationList',
              'productTypeList',
              'productLineList',
              'vendorList',
              'loggedInUser',
              'reportImageBasePath',
              'inventoryList',
              'inventoryTotals'
    
          ]),
         sortedList(){
            if(this.sortBy=='default'){return this.inventoryList}

            var sort1 = this.sortaccending ? -1:1
            var sort2 = this.sortaccending ? 1:-1


            switch (this.sortBy) {
                case 'code':
                    function compare(a,b){
                        if (a.code < b.code)
                            return sort1
                        if(a.code > b.code)
                            return sort2
                        return 0
                    }
                    return this.inventoryList.sort(compare)
                    break;
                case 'name':
                    function compareb(a,b){
                        if (a.name.toLowerCase() < b.name.toLowerCase())
                            return sort1
                        if(a.name.toLowerCase() > b.name.toLowerCase())
                            return sort2
                        return 0
                    }
                    return this.inventoryList.sort(compareb)
                    break;
                case 'line':
                    function comparec(a,b){
                        if (a.lineName < b.lineName)
                            return sort1
                        if(a.lineName > b.lineName)
                            return sort2
                        return 0
                    }
                    return this.inventoryList.sort(comparec)
                    break;    
                case 'size':
                    function compared(a,b){
                        if (a.size < b.size)
                            return sort1
                        if(a.size > b.size)
                            return sort2
                        return 0
                    }
                    return this.inventoryList.sort(compared)
                    break;   
                
                case 'preforder':
                    function comparee(a,b){
                        if (a.preferredOrderQty < b.preferredOrderQty)
                            return sort1
                        if(a.preferredOrderQty > b.preferredOrderQty)
                            return sort2
                        return 0
                    }
                    return this.inventoryList.sort(comparee)
                    break;
                case 'onhand':
                    function comparef(a,b){
                        if (a.onHandQty < b.onHandQty)
                            return sort1
                        if(a.onHandQty > b.onHandQty)
                            return sort2
                        return 0
                    }
                    return this.inventoryList.sort(comparef)
                    break;
               
                case 'price':
                    function compareg(a,b){
                        if (a.unitPrice < b.unitPrice)
                            return sort1
                        if(a.unitPrice > b.unitPrice)
                            return sort2
                        return 0
                    }
                    return this.inventoryList.sort(compareg)
                    break; 
                case 'cost':
                    function compareh(a,b){
                        if (a.unitCost < b.unitCost)
                            return sort1
                        if(a.unitCost > b.unitCost)
                            return sort2
                        return 0
                    }
                    return this.inventoryList.sort(compareh)
                    break; 
                case 'location':
                    function comparei(a,b){
                        if (a.locationName < b.locationName)
                            return sort1
                        if(a.locationName > b.locationName)
                            return sort2
                        return 0
                    }
                    return this.inventoryList.sort(comparei)
                    break; 
                case 'target':
                    function comparel(a,b){
                        if (a.targetQty < b.targetQty)
                            return sort1
                        if(a.targetQty > b.targetQty)
                            return sort2
                        return 0
                    }
                    return this.inventoryList.sort(comparel)
                    break; 
                case 'status':
                    function comparem(a,b){
                        if (a.isActive < b.isActive)
                            return sort1
                        if(a.isActive > b.isActive)
                            return sort2
                        return 0
                    }
                    return this.inventoryList.sort(comparem)
                    break; 
                default:
                    return this.inventoryList
                    break;
            } 
         },
         totalsString(){
            if(this.inventoryTotals==undefined){return ""}
            return "Total On Hand: " + this.inventoryTotals.onHandTotal
         },
         

      },
     
      data(){
          return{
                
                paramTrue:true,
                sortBy:'default',
                sortaccending:true,
                setonhandwarning:'This feature REPLACES the on-hand quantity and there will be no history of the adjustment! To keep the history, you should make changes through “Receive Stock” (add) or “Consume Stock” (deduct) adjustments. Do you wish to continue?',
                resetallonhandwarning:'This feature RESETS ALL products to on hand quantity of 0 for this location. THIS CANNOT BE UNDONE. Do you wish to continue?',
                reportURL:null

          }
      },
      methods:{
        async printInventory(){
            var lineid = null
                if (this.inventoryFilter.lineId!=-1){
                    lineid=this.inventoryFilter.lineId
                }
            let req = {
                
                  Report:{Name:'Inventory Snapshot',
                    Parameters:[
                        {
                            SPParamName:'@SalonID',
                            CurrentValue:this.inventoryFilter.locationId
                        },
                        {
                            SPParamName:'@productline',
                            CurrentValue:lineid
                        }
               
                    ]
                  }
              }
              this.$store.dispatch('beforeAPI');
              var response = await this.callAPI_Report(req,'generate')
              //handle response
              if(response.Successful){
                if(response.Instances==null){
                    console.log('no instance')
                    return;
                }

                
                this.reportURL = this.reportImageBasePath + response.Instances[0].ExportPath
                this.$nextTick(() => {
                     this.$refs.inventoryreportlink.click()
                })
                //this.$refs.mymodal.closeModal()
              }else{
                  this.responseMessage = response.Message
              }
              this.pleaseWait=false; 
        },
        setonhand(){
            this.$refs.onhand.startAdd()
        },
        formatmoney(i){
            return i.toFixed(2)
        },
        selectLocation(){
            console.log('sel loc')
            this.GetTypes();
            this.GetLines();
            this.GetVendors();
            this.filterinventory()
        },
        setsort(s){
            if(s==this.sortBy){this.sortaccending=!this.sortaccending}
            else{
                this.sortBy=s
            }
        },
        recordClass(r){
            return "invrecord"
         },
        resetfilter(){
            this.inventoryFilter={
                lineId:-1,
                typeId:-1, 
                vendorId:-1,
                locationId:this.loggedInUser.LocationId,
                filterString:null
            }
            this.filterinventory()
        } ,
        async filterinventory(){
            console.log('filter inv!')
            let req = {
                  InventoryFilter:this.inventoryFilter
              }
              this.$store.dispatch('beforeAPI');
              var response = await this.callAPI(req,'inventory')
              //handle response
              if(response.Successful){
                   this.inventoryList =response.InventoryItemList;
                   this.inventoryTotals = response.InventoryTotals
                   this.sortBy='default'
              }else{
                  this.responseMessage = response.Message
              }
              this.pleaseWait=false;  
        },
        async GetTypes(){
            let req = {
                  locationId:this.inventoryFilter.locationId
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'producttype')

            if(response.Successful){
                this.productTypeList=response.productTypeList
            
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        async GetLines(){
            let req = {
                  locationId:this.inventoryFilter.locationId
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'productline')

            if(response.Successful){
                this.productLineList=response.ProductLineList
            
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        async GetVendors(){
            let req = {
                  locationId:this.inventoryFilter.locationId
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'vendor')

            if(response.Successful){
                this.vendorList=response.vendorList
            
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        },
        async getreportImageBasePath(){
                console.log('img')
                //call api
                
                var response = await this.callAPI(null,'reportimagepath')
                //handle response
                if(response.Successful){
                    
                    this.reportImageBasePath = window.VUE_CONFIG_ReportAPI + response.StringResponse
                    console.log('img' + this.reportImageBasePath)
                }else{
                    this.message = response.Message
                }
                this.pleaseWait=false;
            }, 
        async resetonhand(){
            let req = {
                  locationId:this.inventoryFilter.locationId,
                  personId:this.loggedInUser.user_personId
            }
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'resetonhandall')

            if(response.Successful){
                this.filterinventory()
            
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
        }
    },
    mounted(){
        this.getreportImageBasePath()
        this.inventoryFilter.locationId=this.loggedInUser.LocationId
            this.selectLocation()
    }
  }
  </script>
  
  <style>
  .inventory{
    height:75vh;
  }
 .invheader{
    text-align:left;
    
    margin:5px;
    border-radius: 2px;
    padding:0px 20px 0px 5px;
    display:grid;
    grid-template-columns: 1fr 1fr 3fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
 }
 .invrecord{
    text-align:left;
    background-color:var(--frame-color);
    margin:5px;
    border-radius: 2px;
    padding:0px 0px 0px 5px;
    display:grid;
    grid-template-columns: 1fr 1fr 3fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;
 }
 .vertScrollinv2{
    overflow-x:hidden;
    overflow-y:scroll;
    width:100%;
    height:440px;
 }
 .filterinput2{
    height:28px;
    margin-top:5px;
    margin-left:5px;
    font-size:inherit;
 }
 .invrecordlabel{
   margin:10px 20px 10px 5px;
   
 }
 .invrecordlabel2{
    margin:10px 10px 10px 10px;
    min-width:25px;
    /* border:1px solid gray; */
  }
 .invbutton{
    border: none;
border-radius:5px;
width:120px;
height:40px;
padding:0px;
font-size: medium;
margin:5px;
text-decoration: none;
color:var(--light-text-color);
 }
 .inactivelabel{
    margin:14px 10px 10px 5px;
 }
  </style>