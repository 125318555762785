<template>
 
      <div class="oclookupdetail" v-if="openClose.openCloseTasks!=undefined">
          

            <!-- <label class="titlelabel">Open Close</label> -->
           
              <div class="oclookup bordered vertFlexStretch">
                
                  <div class="vertFlex">
                    
                    <!-- <input type="checklist" class="checklist"> -->
                    <div v-if="!openClose.isOpen">
                      <label for="">Open Tasks</label>
                      <div class="horizFlex topMargin centered" v-for="task in openClose.openCloseTasks.openTasks" :key="task.id" :value="task.id">
                        <input class="entryCheckbox" type="checkbox" name="myopentasks" v-model="task.selected" >
                        <label for="" class="entryCheckLabel">{{task.name}}</label>
                      </div>
                    </div>
                    <div v-if="openClose.isOpen">
                      <label for="">Close Tasks</label>
                      <div class="horizFlex topMargin centered" v-for="task in openClose.openCloseTasks.closeTasks" :key="task.id" :value="task.id">
                        <input class="entryCheckbox" type="checkbox" name="myopentasks" v-model="task.selected" >
                        <label for="" class="entryCheckLabel">{{task.name}}</label>
                      </div>
                    </div>

                    
                  </div>
                  <label for="" >{{openClose.previousNote}}</label>
                  <textarea name="" ref="note" cols="30" rows="10" placeholder="Enter Note" v-model="currentNote"></textarea>
 
              </div>
              <div class="money  bordered left10">
                <div v-if="pleaseWait">
                  <label for="" class="pleasewaitlabel">Please Wait...</label>
               </div> 
                <label class="leftjustified statuslabel">{{ statusLabel }}</label>
                <div class="cash ">
                  <div class="">
                    <cashentry ref="Pennies" @updatecashtotal="updateCashTotal()"/>
                    <cashentry ref="Nickels" label='Nickels' mathLabel='0.05' rollLabel='40' @updatecashtotal="updateCashTotal()"/>
                    <cashentry ref="Dimes" label='Dimes' mathLabel='0.10' rollLabel='50'  @updatecashtotal="updateCashTotal()"/>
                    <cashentry ref="Quarters" label='Quarters' mathLabel='0.25' rollLabel='40'  @updatecashtotal="updateCashTotal()"/>
                    <cashentry ref="Ones" label='Ones' mathLabel='1.00' :rollLabel='zero'   @updatecashtotal="updateCashTotal()"/>
                    <cashentry ref="Fives" label='Fives' mathLabel='5.00' :rollLabel='zero'  @updatecashtotal="updateCashTotal()"/>
                    <cashentry ref="Tens" label='Tens' mathLabel='10.00' :rollLabel='zero'  @updatecashtotal="updateCashTotal()"/>
                    <cashentry ref="Twenties" label='Twenties' mathLabel='20.00' :rollLabel='zero'  @updatecashtotal="updateCashTotal()"/>
                    <cashentry ref="Fifties" label='Fifties' mathLabel='50.00' :rollLabel='zero'  @updatecashtotal="updateCashTotal()"/>
                    <cashentry ref="Hundreds" label='Hundreds' mathLabel='100.00' :rollLabel='zero'  @updatecashtotal="updateCashTotal()"/>
                    <cashentry ref="Other" label='Other' mathLabel='' :rollLabel='zero'  @updatecashtotal="updateCashTotal()"/>
                    <div class="totaldiv">
                      <label for=""  class="">Cash Total: </label>
                      <label for=""  class="">{{toCurrency(cashTotal)}}</label>
                    </div>
                    
                  </div>
                  <div class=" vertFlexStretch">
                    <div class="borderbox vertFlexLeft left40">
                       <select name="" id="" class='entrySelect selaccount' v-model="location.id"  @change="selectLocation()" >
                          <option v-for="loc in ocLocationList" :key="loc.id" :value="loc.id" >
                              {{loc.name}}
                          </option>
                      </select>

                      <select name="" id="" class='entrySelect selaccount' v-model="terminalId"   @change="selectTerminal()" >
                          <option v-for="t in terminalList" :key="t.id" :value="t.id" >
                              {{t.name}}
                          </option>
                      </select>
                      <!-- <label for="" class="locationInfo">Location</label>
                      <label for="" class="locationInfo">Terminal</label> -->
                      <label for="" class="locationInfo">{{openCloseDateTime}}</label>
                      
                        <label for="" class=" locationInfo">Opening Cash Amount: {{toCurrency(openClose.openingCash)}}</label>
                      
                      
                      <!-- <smallbutton toplabel="Update Opening Cash"/> -->
                    </div>
                    
                    <div>
                      <div class="expectedOverShort ">
                        <label for=""  class="rightjustified">Expected</label>
                        <label for=""  class="rightjustified">Over/Short</label>
                      </div>
                      <div class="expectedOverShort">
                        <label for=""  class="rightjustified">{{expectedCash}}</label>
                        <label for=""  :class="overshortclass">{{overShortCash}}</label>
                      </div>
                    </div>
                    
                  </div>
                </div>
                <div class="noncash" v-if="openClose.isOpen"> 

                  <div>
                      
                      <div class="totaldiv">
                        <label for=""  class="">BankCard:</label>
                        <label for=""  class="rightjustified">{{toCurrency(expectedCard)}}</label>
                      </div>
                      <div class="totaldiv">
                        <label for=""  class="rightjustified">Check:</label>
                        <label for=""  class="rightjustified">{{toCurrency(expectedCheck)}}</label>
                      </div>
                      <div class="totaldiv">
                        <label for=""  class="rightjustified">Gift Card:</label>
                        <label for=""  class="rightjustified">{{toCurrency(expectedGift)}}</label>
                      </div>
                      <div class="totaldiv">
                        <label for=""  class="rightjustified">Other:</label>
                        <label for=""  class="rightjustified">{{toCurrency(expectedOther)}}</label>
                      </div>
                    </div>
                    <br>
                      <div>
                        <tickets :showButton="trueParam" :businessDayId="openClose.SalonBusinessDay.id" :terminalId="terminalId"/>
                      </div>
                  
                  <!-- <div> -->
                    <!-- <payentry v-if='includeCard'/> -->
                    <!-- <payentry label="Check" compareLabel="Compare Check" v-if='includeCheck'/>
                    <payentry label="Gift Card" compareLabel="Compare Gift Card" v-if='includeGift'/>
                    <payentry label="Flex Dollars" compareLabel="Compare Flex" v-if='includeOther'/>
                    <payentry label="Paid Out" compareLabel="Paid In/Out" v-if='includePaidOut'/>
                    
                    <payentry label="Grand Total" compareLabel="Review Transactions" :allowTotalEntry='false'/> -->
                    
                  <!-- </div> -->
                 <!--  <div>
                    <div class="expectedOverShort">
                        <label for=""  class="rightjustified">0.00</label>
                        <label for=""  class="rightjustified">0.00</label>
                      </div>
                      <div class="expectedOverShort">
                        <label for=""  class="rightjustified">0.00</label>
                        <label for=""  class="rightjustified">0.00</label>
                      </div>
                      <div class="expectedOverShort">
                        <label for=""  class="rightjustified">0.00</label>
                        <label for=""  class="rightjustified">0.00</label>
                      </div>
                      <div class="expectedOverShort">
                        <label for=""  class="rightjustified">0.00</label>
                        <label for=""  class="rightjustified">0.00</label>
                      </div>
                  </div> -->
                  
                </div>
                <div class="" v-if="openClose.isOpen">
                  <hr>
                  <hr>
                  <div class="horizFlexStretch">
                      <label for=""  class="">Cash To Deposit: {{ toDeposit }}</label>
                      <label for=""  class="">Cash To Leave In Till: {{toCurrency(openClose.defaultStartingTillAmount)}}</label>
                  </div>
                </div>
              </div>
              <div class="buttons ">
                <!-- <smallbutton toplabel="Open Till"></smallbutton> -->
                <confirm ref="confirmOpen" :body="getOpenString()" title="Open School" @yes="OpenMe()" v-if="!openClose.isOpen"/>
                <confirm ref="confirmClose" :body="getCloseString()" title="Close School" @yes="CloseMe()" v-if="openClose.isOpen"/>
                <confirm ref="confirmReopen" :body="reopenstring" title="Reopen Terminal" @yes="ReOpenMe()" :showButton="falseParam"/>
                <smallbutton toplabel="Close School" v-if="openClose.isOpen" @click="OKToClose()"></smallbutton>
                <smallbutton toplabel="Open School" v-if="!openClose.isOpen" @click="OKToOpen()"></smallbutton>
                
                <div>
                  <label class="responsemessage">{{ responseMessage }}</label>
                </div>
                
              </div>
              <messagebox ref="messagebox" :body="OpenCloseMessageString" :title="OpenCloseMessageTitle" @ok="this.$emit('hide')"/>
      </div>
          
          
            
          

  
</template>

<script>
import {mapFields} from 'vuex-map-fields'
import cashentry from '../controls/cashentry.vue'
import payentry from '../controls/payentry.vue'
import smallbutton from '../controls/button.vue'
import datamethods from '../../services/datamethods.js'
import format from '../../format'
import confirm from '../actions/confirm.vue'
import tickets from './ticket.vue'
import messagebox from '../actions/messagebox.vue'
export default {
    name:'openclose',
    components:{
        cashentry,
        payentry,
        smallbutton,
        confirm,
        tickets,
        messagebox
 
    },
    mixins:[datamethods,format],
    computed:{
      ...mapFields([
          'ocLocationList',
          'terminalList',
          'location',
          'terminalId',
          'openClose',
          'cashTotal',
          'cashOverShort',
          'currentNote',
          'pleaseWait',
          'pleaseWaitText',  
          'OpenCloseMessageString',
          'OpenCloseMessageTitle' ,
          'HeaderOpenCloseMessageString'
          ]),
      overshortclass(){
        if(this.overShortCash>=0){return "rightjustified"}
        else{return "rightjustified negative"}
      },
      statusLabel(){
        var lbl=this.location.name + ' Terminal ' + this.terminalId + ' is '
        if(this.openClose.isOpen){
          lbl = lbl + 'OPEN'
        }else{ lbl = lbl + 'CLOSED'}
        return lbl
      },
        openCloseDateTime(){
          if(this.openClose.isOpen){
            if(this.openClose.thisTerminal.openDateTime.includes('0001-01-01')){return 'Opened date time:'}
            return "Opened date time: "+ this.$options.filters.formatDate(this.openClose.thisTerminal.openDateTime)
          }else{
            if(this.openClose.thisTerminal.closeDateTime.includes('0001-01-01')){return 'Closed date time:'}
            return "Closed date time: " +this.$options.filters.formatDate(this.openClose.thisTerminal.closeDateTime)
          }
         
        },
        expectedCash(){
          return Number(this.openClose.expectedCash).toFixed(2)
        },
        expectedCard(){
          return Number(this.openClose.CloseTargets.cardTarget).toFixed(2)
        },
        expectedGift(){
          return Number(this.openClose.CloseTargets.giftCardTarget).toFixed(2)
        },
        expectedCheck(){
          return Number(this.openClose.CloseTargets.checkTarget).toFixed(2)
        },
        expectedOther(){
          return Number(this.openClose.CloseTargets.otherTarget).toFixed(2)
        },
        overShortCash(){
           return Number(this.cashOverShort).toFixed(2)
        },
        toDeposit(){
          return (Number(this.cashTotal) - Number(this.openClose.defaultStartingTillAmount)).toFixed(2)
        }
    },
    mounted(){
        this.getOpenCloseData()
    },
    data(){
        return{
          zero:0,

          

          /*  includeCheck:false,
           includeCard:true,
           includeGift:false,
           includeOther:true,
           includePaidOut:true, */
     
           trueParam:true,
           falseParam:false,
           responseMessage:null,
           suspendedticketList:[],
           suspendedTicketCount:0,
           authorizedSuspendedTicketCount:0,
           reopenstring:'Do you want to Reopen this terminal?  If NO, then you must close all the other terminals before reopening the school.'

        }
    },
    mounted(){
      this.getLocations();
    },
    methods:{
      
      OKToOpen(){
        this.responseMessage=null
        if(this.openTasksComplete()==false){
          this.responseMessage="Please complete all tasks before opening."
          return false
        }
        this.checkForReopen()
        //this.$refs.confirmOpen.Show()
      },
      async checkForReopen(){
        let req = {
                locationId:this.location.id,
                terminalId:this.terminalId,
                businessDayId:this.openClose.SalonBusinessDay.id
            }
             this.$store.dispatch('beforeAPI');
             this.responseMessage=null
            var response = await this.callAPI(req,'checkforreopen')
            //handle response
            if(response.Successful){
                if(response.ResponseInteger==1){
                  this.$refs.confirmReopen.Show()
                }else{
                  //go ahead
                  this.$refs.confirmOpen.Show()
                }
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
      },
      getOpenString(){
        var msg = "Are you sure that you want to open the school?"
        if(this.overShortCash=='0.00'){
          //nothing
        }
        else if(Number(this.overShortCash) >0){
          msg = msg + "  You are over $" + this.overShortCash
        }else if(Number(this.overShortCash) <0){
          msg = msg + "  You are short $" + this.overShortCash
        }
        return msg
      },
      OKToClose(){
        this.gettickets(true)
        
      },
      OKToClosePart2(){
        this.responseMessage=null
        if(this.closeTasksComplete()==false){
          this.responseMessage="Please complete all tasks before closing."
          return false
        }
        if(this.authorizedSuspendedTicketCount>0){
          this.responseMessage="You have suspended tickets with authorized card payments. Please go to the POS to void those tickets before closing."
          return false
        }
        this.$refs.confirmClose.Show()
      },
      getCloseString(){
        var msg = "Are you sure that you want to close the school?"
        if(this.overShortCash >0){
          msg = msg + "  You are over $" + this.overShortCash
        }else if(this.overShortCash <0){
          msg = msg + "  You are short $" + this.overShortCash
        }
        if(this.suspendedTicketCount>0){
          msg = msg + " There are " + this.suspendedTicketCount + " suspended tickets. Those tickets will be canceled when you close."
        }
        return msg
      },
      clearCashEntries(){
        console.log('clearcash')
        this.$refs.Pennies.clear()
        this.$refs.Nickels.clear()
        this.$refs.Dimes.clear()
        this.$refs.Quarters.clear()
        this.$refs.Ones.clear()
        this.$refs.Fives.clear()
        this.$refs.Tens.clear()
        this.$refs.Twenties.clear()
        this.$refs.Fifties.clear()
        this.$refs.Hundreds.clear()
        this.$refs.Other.clear()
        this.$refs.Pennies.focus()
        this.currentNote=null
        this.updateCashTotal()
      },
      openTasksComplete(){
        //check for tasks
        var allchecked=true
        this.openClose.openCloseTasks.openTasks.forEach(myFunction2)
            
            function myFunction2(item, index, arr) {
                let p=arr[index]
                if(p.selected==false){
                    allchecked=false
                }
            } 
        return allchecked
      },
      closeTasksComplete(){
        //check for tasks
        var allchecked=true
        this.openClose.openCloseTasks.closeTasks.forEach(myFunction2)
            
            function myFunction2(item, index, arr) {
                let p=arr[index]
                if(p.selected==false){
                    allchecked=false
                }
            } 
        return allchecked
      },

      async OpenMe(){
        
        let req = {
                locationId:this.location.id,
                terminalId:this.terminalId,
                cashTotal:this.cashTotal,
                note:this.currentNote
            }
             this.$store.dispatch('beforeAPI');
             this.responseMessage=null
            var response = await this.callAPI(req,'open')
            //handle response
            if(response.Successful){
                this.getOpenCloseData() //refresh!
                this.OpenCloseMessageString="You opened " + this.location.name
                
                this.OpenCloseMessageTitle="Opened School"
                this.$refs.messagebox.Show()
                //this.$emit('hide')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
      },
      async ReOpenMe(){
        
        let req = {
                locationId:this.location.id,
                terminalId:this.terminalId,
                cashTotal:this.cashTotal,
                note:this.currentNote,
                businessDayId:this.openClose.SalonBusinessDay.id,
                isReopen:true
            }
             this.$store.dispatch('beforeAPI');
             this.responseMessage=null
            var response = await this.callAPI(req,'open')
            //handle response
            if(response.Successful){
                this.getOpenCloseData() //refresh!
                this.OpenCloseMessageString="You reopened " + this.location.name + ' terminal ' + this.terminalId 
                this.OpenCloseMessageTitle="Reopened School"
                this.$refs.messagebox.Show()
                //this.$emit('hide')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
      },
      async CloseMe(){
        let req = {
                locationId:this.location.id,
                terminalId:this.terminalId,
                cashTotal:this.cashTotal,
                note:this.currentNote,
                closeTargets:this.openClose.CloseTargets,
                businessDayId:this.openClose.SalonBusinessDay.id
            }
             //***this.$store.dispatch('beforeAPI');
             this.HeaderOpenCloseMessageString="Closing " + this.location.name + '. This may take a few minutes. You may continue working, and this message will update when the close is complete.'
             this.responseMessage=null
            var response = await this.callAPI(req,'close')
            //handle response
            if(response.Successful){
              this.pleaseWait=false; 
                this.getOpenCloseData() //refresh!
                this.OpenCloseMessageString="You closed " + this.location.name
                this.HeaderOpenCloseMessageString="You closed " + this.location.name
                this.OpenCloseMessageTitle="Closed School"
                //this.$refs.messagebox.Show()
                 //this.$emit('hide')
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
      },
      updateCashTotal(){
        
        var total=0
        total = total + Number(this.$refs.Pennies.amount)
        total = total + Number(this.$refs.Nickels.amount)
        total = total + Number(this.$refs.Dimes.amount)
        total = total + Number(this.$refs.Quarters.amount)
        total = total + Number(this.$refs.Ones.amount)
        total = total + Number(this.$refs.Fives.amount)
        total = total + Number(this.$refs.Tens.amount)
        total = total + Number(this.$refs.Twenties.amount)
        total = total + Number(this.$refs.Fifties.amount)
        total = total + Number(this.$refs.Hundreds.amount)
        total = total + Number(this.$refs.Other.amount)
        this.cashTotal = Number(total).toFixed(2)
        console.log('total ' + total)

        this.cashOverShort=(Number(total) -Number(this.openClose.expectedCash)).toFixed(2)
      },
      toCurrency(value){
        return "$" + Number(value).toFixed(2);
      },
        async getOpenCloseData(){
          console.log('refresh!!')
            let req = {
                locationId:this.location.id,
                terminalId:this.terminalId
            }
             this.$store.dispatch('beforeAPI');
             this.responseMessage=null
            var response = await this.callAPI(req,'openclose')
            //handle response
            if(response.Successful){
                 this.openClose=response.OpenClose
                 this.clearCashEntries()
                 this.gettickets()
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false; 
        },
        async getLocations(){
          
          //call api
          this.$store.dispatch('beforeAPI');
          this.responseMessage=null
          var response = await this.callAPI(null,'locations')
          //handle response
      
          if(response.Successful){
            
            this.ocLocationList= response.locationList
            if(this.ocLocationList.length>0){
              console.log('loc ' + this.location.id)
              if(this.location.id==-1){
                this.location.id = this.ocLocationList[0].id
                this.location.name=this.ocLocationList[0].name
                
              }else{
                this.locationAndTerminalPreselected=true
              }
              this.selectLocation()
            }

          }else{
            this.responseMessage = response.Message
          }
          this.pleaseWait=false;
        },
        selectLocation(){
          console.log('selloc ' + this.location.id)
          var locid= this.location.id
          var max=0;
          for(var i = 0; i<this.ocLocationList.length;i++){
            if (this.ocLocationList[i].id==locid){
              max=this.ocLocationList[i].maxTerminalId
              this.location.name=this.ocLocationList[i].name
            }
          }
          this.location.maxTerminalId=max
          this.getTerminalList()
          
        },
        getTerminalList(){
          
          //based on selected location
          var max = this.location.maxTerminalId
          if (max==0){
            this.terminalList=[0];
            this.terminalList[0]={id:1, name:'Terminal 1'}
            this.terminalId=this.terminalList[0].id
            this.getOpenCloseData()
            return;
          }
          console.log('max ' + max)
          var tlist=[max];
          for(var i = 0; i<max;i++){
            tlist[i]={id:i+1, name:'Terminal ' + (i+1)}
            
          }
         this.terminalList=tlist
         if(tlist.length>0){
          this.terminalId=tlist[0].id
          this.getOpenCloseData()
         }
        },
        selectTerminal(){
          this.getOpenCloseData()
        },


        async gettickets(runOkToCloseAfter){
            this.inPayment=true
            let req = {
                businessDayId:this.openClose.SalonBusinessDay.id
            }
            //call api
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(req,'getsuspendedtickets')
            //handle response
        
            if(response.Successful){
                this.suspendedticketList=response.ticketList
                this.suspendedTicketCount=this.suspendedticketList.length
                this.authorizedSuspendedTicketCount=0
                if(this.suspendedTicketCount>0){
                  //determine count of authtickets
                  var authCount=0;
                  this.suspendedticketList.forEach(myFunction2)
                    
                    function myFunction2(item, index, arr) {
                        let p=arr[index]
                        if(p.authorizedPaymentCount>0){
                            authCount=authCount+1
                        }
                    } 
                    this.authorizedSuspendedTicketCount=authCount
                    
                }
                if(runOkToCloseAfter){this.OKToClosePart2();}
            }else{
            this.responseMessage = response.Message
            }
            this.pleaseWait=false;
                    
        },

    }
}
</script>

<style>
.cashTotalLabel{
  margin-left:100px;
}
.totaldiv{
  display:grid;
  grid-template-columns:1fr 1fr;
  text-align: right;
  padding-right:5px;
  margin-bottom:5px;
  margin-left:320px;
}
.oclookupdetail{
  height:100%;
     margin:0px;
    display:grid;
    grid-template-columns:1fr 3fr;
    grid-template-rows:auto 50px;
    grid-template-areas: 
    "oclookup money"
    "buttons buttons"
}
.expectedOverShort{
  display:grid;
  grid-template-columns:1fr 1fr;
  margin:9px;
}
.locationInfo{
  padding:10px;
}
.oclookup{
  grid-area: oclookup;
  display:flex;
  flex-direction: column;
}
.money{
  grid-area: money;
  display:grid;
    grid-template-columns:auto;
    grid-template-rows:auto auto;
  
}
.cash{
  display:grid;
    grid-template-columns:3fr 2fr;
    grid-template-rows:auto;
}
.noncash{
  display:grid;
    grid-template-columns:3fr 2fr;
    grid-template-rows:auto;
}
.buttons{
  grid-area: buttons;
}
.checklist{
  height:70px;
  margin-bottom:50px;
}
.borderbox{
  border: solid gray 1px;
}
.statuslabel{
  font-weight:bold;
  margin-bottom:10px;
  font-size:x-large;
}
</style>