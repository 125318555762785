<template>
    <div class="lookupdetail">
        <div class="lookup">
            <form  ref="lookupform" v-on:keyup.enter="lookup">
                <div class="vertFlexLeft">
                    <div class="lookupHeader">
                        <label class="titlelabel bottom10">{{lookupPromptText}}</label>
                        <linkbutton toplabel="Clear" @click="clearLookup()" class="clearlink"/>
                    </div>
                    
                    <div >
                        <div class="entryTextDiv">
                            <label class="entryLabel" for="">Location</label>
                        </div>
                        <select name="" id="" class='entrySelect' v-model="locationId" required>
                            <option v-for="loc in locationList" :key="loc.id" :value="loc.id" >
                                {{loc.name}}
                            </option>
                        </select>
                    </div>
                    <formentrystacked  label="Name/Code" v-model="productString" minlength="3"  />
                    <div class="horizFlex ">
                        <input class="entryCheckbox" type="checkbox" v-model="showInactive" >
                        <label for="" class="mediumtext">Show Inactive</label>
                    </div>
                    <div class="lookupHeader">
                        <updateproduct :locationId="locationId" @refresh="Refresh" :isAdd="trueParam" buttonclass="innerbutton" @refreshnew="Refresh"/>
                        <smallbutton @click="lookup()" toplabel="Go" buttonclass="button buttonprimary innerbutton"/>
                    </div>
                    
                </div>
            </form>
  
            <div v-if="pleaseWait">
                <label for="" class="pleasewaitlabel">{{pleaseWaitText}}</label>
            </div> 
            <div>
                <label class="responsemessage">{{responseMessage}}</label>
            </div>
                <div class="resultsdiv" v-if="lookupresults.length>0">
                <div v-for="result in lookupresults" :key="result.id" :value="result.id" >
                    <lookupresult :topLeft="buildResultName(result)"  :bottomLeft="toCurrency(result.price)"    @click="select(result)"
                        :selected="result.id==selectedResultID" :inactive="!result.isActive">
                    
                    </lookupresult>  
                </div>
            </div>
        </div>
  
        <div v-if="selectedResultID>0" class="pad10">
            <div class="bordered">
                <div class="horizFlexStretch">
                    <div class="horizFlex">
                        <label class="titlelabel bold right10">{{currentProduct.name}} </label>
                        <updateproduct :inProduct="currentProduct" :locationId="locationId" @refresh="Refresh"  />
                    </div>
                    <linkbutton toplabel="Refresh" @click="Refresh()" class="clearlink"/>
                </div>
                <div class="splitDiv ">
                    <!-- left col -->
                    <div class="leftjustified vertFlex padright">
                        
                        
                        <div class="dataitem" v-if="isVisible('Name')">
                            <p >Name:</p>
                            <p >{{currentProduct.name}}</p>
                        </div>
                        <div class="dataitem"  v-if="isVisible('Type')">
                            <p >Type:</p>
                            <p >{{currentProduct.typeName}}</p>
                        </div>
                        <div class="dataitem" v-if="isVisible('Line')">
                            <p >Line:</p>
                            <p >{{currentProduct.lineName}}</p>
                        </div>
                        
                        <div class="dataitem"  v-if="isVisible('Vendor')">
                            <p >Vendor:</p>
                            <p >{{currentProduct.vendorName}}</p>
                        </div>
                        <hr/>
                        <div class="dataitem"  v-if="isVisible('Cost')">
                            <p >Cost:</p>
                            <p >{{toCurrency(currentProduct.cost)}}</p>
                        </div>
                        <div class="dataitem"  v-if="isVisible('Price')">
                            <p >Price:</p>
                            <p >{{toCurrency(currentProduct.price)}}</p>
                        </div>
                        <div class="dataitem"  v-if="isVisible('IsRetail')">
                            <p >Retail:</p>
                            <p >{{currentProduct.isRetail}}</p>
                        </div>
                        <div class="dataitem"  v-if="isVisible('IsTaxable')">
                            <p >Taxable:</p>
                            <p >{{currentProduct.isTaxable}}</p>
                        </div>
                        <div class="dataitem"  v-if="isVisible('IsFinancialAid')">
                            <p >FA Eligible:</p>
                            <p >{{currentProduct.isFinancialAidEligible}}</p>
                        </div>
                        <div class="dataitem"  v-if="isVisible('IsFinancialAid') && currentProduct.isFinancialAidEligible">
                            <p >{{FACodeText}} - FA</p>
                            <p >{{glCode_Anthology_FA}}</p>
                        </div>
                        <div class="dataitem"  v-if="isVisible('IsFinancialAid')">
                            <p >{{FACodeText}} - Cash</p>
                            <p >{{glCode_Anthology_Cash}}</p>
                        </div>
                        <hr/>
                        <div class="dataitem"  v-if="isVisible('Code')">
                            <p >Code:</p>
                            <p >{{currentProduct.code}}</p>
                        </div>
                        <div class="dataitem"  v-if="isVisible('UPC')">
                            <p >UPC: </p>
                            <p >{{currentProduct.upc}}</p>
                        </div>
                        
                        <hr/>
                        <div class="dataitem"  v-if="isVisible('PreferredOrder')">
                            <p >Prefer. Ord.:</p>
                            <p >{{currentProduct.preferredOrderQuantity}}</p>
                        </div>
                        <div class="dataitem"  v-if="isVisible('Target')">
                            <p >Reorder Alert Qty:</p>
                            <p >{{currentProduct.targetQuantity}}</p>
                        </div>
                        <div class="dataitem"  v-if="isVisible('OnHand')">
                            <p >On Hand:</p>
                            <p >{{currentProduct.onHandQuantity}}</p>
                        </div>
                        
                    </div>

                    <!-- right col -->
                    <div class="leftjustified vertFlex ">
                        
                        <div>
                            <label class="  right10">Locations:</label>
                            <div v-for="pl in currentProduct.productLocationList" :key="pl.id" :value="pl.id" >
                                <div class="locationdiv">
                                    <img src="@/assets/check.png" class="locationcheck" v-if="pl.isActive" />
                                    <p v-if="!pl.isActive"></p>
                                    <p >{{pl.locationName}}</p>
                                </div>
                                
                            </div>
                        </div>

                        <div class="horizFlex">
                            <div class="vertFlex">
                                <!-- print barcodes-->
                                <barcodes :showButton="trueParam"  class="innerbutton" :locationId="this.locationId" :productId="currentProduct.id" 
                                    :onHand="currentProduct.onHandQuantity" @barcodes="printbarcodes"/> 
                                <a :href="barcodesURL" target="_blank" class="reportLink" v-if="barcodesURL!=null">
                                    Download Barcodes
                                    <!-- <smallbutton toplabel="Download Barcodes" ></smallbutton> -->
                                </a>
                            </div>
                            
                            <div class="vertFlex">
                                <!-- print price labels-->
                                <pricelabels :showButton="trueParam"  class="innerbutton" :locationId="this.locationId" :productId="currentProduct.id" 
                                    :onHand="currentProduct.onHandQuantity" @pricelabels="printpricelabels"/> 
                                <a :href="pricelabelURL" target="_blank" class="reportLink" v-if="pricelabelURL!=null">
                                    Download Price Labels
                                    <!-- <smallbutton toplabel="Download Barcodes" ></smallbutton> -->
                                </a>
                            </div>
                        </div>
                        
                        

                            
                            <!-- image -->
                            <div class="showProductImage">
                                <br>
                                <br>
                                <div class="personPhoto" v-if="currentProduct.imageFilename!=null">
                                    <img :src="getProductImage"  width="30" class="productimage" >
                                </div>
                                <smallbutton toplabel="Add/Update Product Image" @Click="addfile"/>
                                <imagealldoc ref="imgdoc" fileAccept="*.*"  :allowFileSelect="trueParam" :allowCapture="falseParam" @saveimg="saveimg_document"  :showImage='falseParam' />
                             
                            </div>
                               
                        
                            <label>{{resetMsg}}</label>



                    </div>
                </div>
                
            </div>

        </div>
        
    </div>
  </template>
  
  <script>
  import {mapFields} from 'vuex-map-fields'
  import datamethods from '../../services/datamethods.js'
  import format from '../../format.js'
  import smallbutton from '../controls/button.vue'
  import formentrystacked from '../controls/entry-stacked.vue'
  import entrySelect from '../controls/entry-select'
  import lookupresult from '../controls/lookupresult.vue'
  import linkbutton from '../controls/linkbutton'
  import updateproduct from '../actions/updateproduct.vue'
  import imagealldoc from '../controls/ImageAll.vue'
  import pricelabels from '../actions/pricelabels.vue'
import barcodes from '../actions/barcodes.vue'
  

  export default {
      name:'productdmin',
      mixins:[datamethods, format],
      components:{
          smallbutton,
          formentrystacked,
          entrySelect,
          lookupresult,
          linkbutton,
          updateproduct,
            barcodes,
            imagealldoc,
            pricelabels
      },
      computed:{
          ...mapFields([
    
              'pleaseWait',
              'pleaseWaitText',
              'lastRefreshDateTime',
              'imagepath',
              'locationList',
            'locationId',
            'productString',
            'showInactive',
            'lookupresults',
            'selectedResultID',
            'currentProduct',
            'barcodesURL',
            'pricelabelURL',
            'loggedInUser'
          ]),
          itemviewclass(){

              return 'itemview'
          },
          showFA(){
                if(window.SHOW_FINANCIALAID==undefined){return false}
                else{return window.SHOW_FINANCIALAID}
            },
            showProductImage(){
                if(window.SHOW_PRODUCT_IMAGE==undefined){return false}
                else{return window.SHOW_PRODUCT_IMAGE}
            },
            getProductImage(){
       
                if(this.currentProduct.imageFilename==null){return null}
                    var imgBasePath =  window.VUE_CONFIG_API + 'personimage/'
                    return imgBasePath + this.currentProduct.imageFilename;
                },
            FACodeText(){
                if(window.FINANCIAL_AID_CODE_TEXT==undefined){return 'FA Code'}
                else{return window.FINANCIAL_AID_CODE_TEXT}
                
            }

      },
      mounted(){
          this.getimagepath()
          if(this.locationId<=0){ this.locationId = this.loggedInUser.LocationId}
          //this.GetLocations()
      },
      data(){
          return{
            lookupPromptText:"Lookup",
            responseMessage:'',
            trueParam:true,
            falseParam:false,
            listenersConnected:true,
            glCode_Anthology_FA:null,
                glCode_Anthology_Cash:null
          }
      },
      methods:{
        printbarcodes(url){
            this.barcodesURL = url
        }, 
        printpricelabels(url){
            this.pricelabelURL = url
        }, 
        toCurrency(value){
            return "$" + Number(value).toFixed(2);
        },
        isVisible(field){
            switch (field) {
                    case "IsFinancialAid":
                        return this.showFA
                        break;
                    default:
                        return true
                        break;
                }
        },
          buildResultName(result){
              var name = result.name
              /* if (!result.IsActive){
                  name = name + ' INACTIVE'
              } */
              return name
          },
          
          async getimagepath(){
  
              this.$store.dispatch('beforeAPI');
              var response = await this.callAPI(null,'documentimagepath')
              //handle response
              if(response.Successful){
                   this.imagepath= response.StringResponse
              }else{
                  this.responseMessage = response.Message
              }
              this.pleaseWait=false;  
          },
          select(result){
              this.selectedResultID=result.id
              this.getItem(result.id)
          },
          Refresh(id){
              console.log('refresh ' + id)
              if(id==undefined){id=this.currentProduct.id}
              this.lookup();
              this.selectedResultID=id
              this.getItem(id)
          },
          RefreshCurrent(){
              this.Refresh(this.currentProduct.id)
          },
          
          async lookup(){
            this.responseMessage=null
            //if(this.productString==null){return}
            //if(this.productString==''){return}
            //console.log('lookup')
            this.lookupresults=[]
            let req = {
                locationId:this.locationId,
                lookupString:this.productString,
                includeInactive:this.showInactive
            }
                //call api
                this.$store.dispatch('beforeAPI');
                var response = await this.callAPI(req,'productlookup')
                //handle response
            
                if(response.Successful){
                    this.lookupresults=response.productList
                    if(this.lookupresults.length==0){
                        this.responseMessage="Product name/code not found at this location"
                    }
                }else{
                this.responseMessage = response.Message
                }
                this.pleaseWait=false;   
             
          },
          
          async getItem(id){
        
     
              let req = {
                  productId:id,
                  locationId:this.locationId,
                  getOnHandQuantity:true,
                  getLocations:true
              }
              this.$store.dispatch('beforeAPI');
              var response = await this.callAPI(req,'productlookup')
              //handle response
              if(response.Successful){
                   this.currentProduct =response.productList[0];
                   //this.$store.dispatch('setCurrentDateTime_Wallet');
                 this.barcodesURL=null
                 this.pricelabelURL=null
                 if(this.currentProduct.glCode_Anthology!=null){
                    var splitcode=this.currentProduct.glCode_Anthology.split("|")

                 
                    if(splitcode.length==2){
                        this.glCode_Anthology_FA =splitcode[0]
                        this.glCode_Anthology_Cash=splitcode[1]
                    }
                    else{
                        this.glCode_Anthology_FA =null
                        this.glCode_Anthology_Cash=splitcode[0]
                    }
                 }
              }else{
                  this.responseMessage = response.Message
              }
              this.pleaseWait=false;  
  
          },
          async GetLocations(){
            
            this.$store.dispatch('beforeAPI');
            var response = await this.callAPI(null,'locations')

            if(response.Successful){
                this.locationList=response.locationList
            
            }else{
                this.responseMessage = response.Message
            }
            this.pleaseWait=false;
       
    },
    addfile(){
        this.$refs.imgdoc.openModal() 
    },
    saveimg_document(file,url,typeId){
                this.storeDocument("doc", file, file.name,typeId)
    },
    
    async storeDocument(filename,file, name,typeId){
        var pId=this.currentProduct.id
        var postParams = new FormData();

        postParams.append(filename,file,name);
        postParams.append('productId',pId);


        var response = await this.callAPI(postParams,'saveproductimage')
        //handle response
        if(response.Successful){
            this.RefreshCurrent() 
        }else{
            this.responseMessage = response.Message
        }
            
    },      
    }
  }
  </script>
  
  <style>
  .reportLink{
    margin-top:40px;
    margin-left:10px;
  }
  .innerbutton{
    height:40px;
    border-radius: 5px;
}
  .splitDiv{
    border-radius:5px;
    margin:20px 0px 0px 0px;
    padding:0px;
    display:grid;
    grid-template-columns:1fr 1fr;
    text-align: left;
}
  .lookupdetail{
       margin:0px;
      display:grid;
      grid-template-columns:1fr 4fr;
      grid-template-rows:auto;
      grid-template-areas: 
      "lookup detail"
  }
  
  .tokenname{
    margin:0px 10px 0px 0px ;
  }
  .note{
    word-wrap:break-word;
  }
  .resultsdiv{
  
      height:350px;
      overflow-x:hidden;
      overflow-y:scroll;
  }
  .transdiv{
      height:250px;
      overflow-x:hidden;
      overflow-y:scroll;
  }
  .itemview{
      padding-left:20px;
      
  }

  .refresh{
      display:flex;
      flex-direction: column;
      text-align: right;
      margin-right:20px;
  }
  .margintop{
      margin-top:5px;
  }
  .lookupHeader{
    display:flex;
    padding:0px;
    flex-direction: row;
    margin:0px 0px 0px 0px ;
    justify-content:space-between;
    width:100%;
  }
  .clearlink{
      margin-top:5px;
  }

  .entry{
    width: 100%;
    display:flex;

    justify-content: space-between;
}
.entryLabel{
    margin:5px;
    font-size:medium;
    text-align: left;   
}
.entryCheckLabel{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
}
.entryCheckLabelbold{
    margin:0px 0px 0px 5px;
    font-size:medium;
    text-align: left;  
    max-width: 420px;
    font-weight:bold;
}
.entryCheckbox{
    margin-left:5px;
}
.entryText{
    margin:5px;
    width:95%;
    font-size:inherit;
    font:inherit;
}
.entrySelect{
    margin:0px 0px 10px 0px;
    width:100%;
    height:25px;
    font-size:inherit;
}
.entryTextDiv{
    display:flex;
    flex-direction: row;
    min-width: 250px;
}

.dataitem{
    display:grid;
    grid-template-columns: 140px auto;
    margin:0px;
    padding:0px;
}
.dataitemwithcheck{
    display:grid;
    grid-template-columns: 100px auto 25px 150px;
    margin:0px;
    padding:0px;
}
.dataitem p{
    margin:3px;
}
.dataitemwithcheck p{
    margin:3px;
}

hr{
    border:solid 1px lightgray;
    width:95%;  
    margin-top:20px;
    margin-bottom:20px;
}
.locationcheck{
    height:15px;
    margin-top:15px;
    margin-right:5px;
}
.locationdiv{
    display:grid;
    grid-template-columns: 1fr 15fr;
}
.productimage{
    margin-left:40px;
}
  </style>